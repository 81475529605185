var defaultConf = require('./config-default');

var BO_URL = 'epl2020-data-dev.mobile-spot.com';
var BO_TAIGA_URL = 'https://pgorganisation.site.calypso-event.net';
var NODE_BACKEND_DOMAIN = 'epl2020-node-backend-dev.mobile-spot.com';
module.exports = require('deepmerge')(defaultConf, {
  ENV: 'dev',
  BO_URL: BO_URL,
  // no trailing '/'
  BO_PATH: '/home/www/eventime/epl/epl2020/epl2020-data-dev.mobile-spot.com',
  // no trailing '/'
  BO_SERVER_ALIAS: 'kim1',
  DEPLOY_PATH: '/home/www/eventime/epl/epl2020/epl2020-dev.mobile-spot.com',
  GOOGLE_ANALYTICS_ID: 'UA-48506479-31',
  // GA ID
  LOGIN: {
    forgottenPasswordURL: "".concat(BO_TAIGA_URL, "/mobile-se/mot-de-passe-oublie.htm")
  },
  projectId: '',
  // FCM sender id
  appId: '',
  // pushwoosh appid
  SEND_EXPORTED_NOTES_MAIL: {
    WS_URL: 'http://localhost:3096/api/exportNotesByMail',
    EXPEDITOR: 'no-reply@mobile-spot.com',
    KEY: 'zpgZkgiz845gjzFzi'
  },
  ADDITIONAL_DEFAULTSRC_CSP: (Array.isArray(defaultConf.ADDITIONAL_DEFAULTSRC_CSP) ? defaultConf.ADDITIONAL_DEFAULTSRC_CSP : []).concat([]),
  CHECK_NETWORK_URL: "https://".concat(BO_URL, "/online.txt"),
  SOCIAL: {
    FEATURE_ENABLED: true,
    URL: "https://".concat(NODE_BACKEND_DOMAIN, "/social")
  }
});