import React from 'react';
import PropTypes from 'prop-types';

const ContactRow = (props) =>
  !props.firstname && !props.lastname ? null : (
    <div className="free-row">
      <div className="prop-img">
        <div className="prop-left">
          <span className="fa fa-user" style={{ fontSize: 20 }} />
        </div>
        <div
          className="prop-right"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            wordBreak: 'break-all',
          }}
        >
          <a
            className="prop-right"
            style={{
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            <span style={{ fontSize: 11, marginRight: 10 }} className="fa fa-user" />{' '}
            {props.firstname} {props.lastname}
          </a>
          {!props.position ? null : (
            <a
              className="prop-right"
              style={{
                fontSize: '15px',
                marginTop: 5,
                marginBottom: 5,
              }}
            >
              <span style={{ fontSize: 11, marginRight: 10 }} className="fa fa-briefcase" />{' '}
              {props.position}
            </a>
          )}
          {!props.phone ? null : (
            <a
              className="link colored-link"
              href={`tel:${props.phone}`}
              style={{
                fontSize: 'small',
                marginTop: 5,
                marginBottom: 5,
              }}
            >
              <span style={{ fontSize: 11, marginRight: 10 }} className="fa fa-phone" />{' '}
              {props.phone}
            </a>
          )}
          {!props.email ? null : (
            <a
              className="link colored-link"
              href={`mailto:${props.email}`}
              style={{
                fontSize: 'small',
                marginTop: 5,
                marginBottom: 5,
              }}
            >
              <span style={{ fontSize: 11, marginRight: 10 }} className="fa fa-envelope" />{' '}
              {props.email}
            </a>
          )}
        </div>
      </div>
    </div>
  );

ContactRow.propTypes = {
  label: PropTypes.string,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  phone: PropTypes.string,
  position: PropTypes.string,
  email: PropTypes.string,
};

export default ContactRow;
