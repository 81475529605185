import { USERS_PAGE_KEY } from 'src/pages/pagesKeys';

import GenericItemPage, { DOM_ID } from 'src/pages/generic-item-page/GenericItemPage';
import UsersContent from './UsersContent';

export default {
  key: USERS_PAGE_KEY,
  path: '/users',
  elId: DOM_ID,
  className: 'users-page',
  component: GenericItemPage,
  childComponent: UsersContent,
  relatedDataToFetch: ['users'],
};
