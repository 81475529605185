import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ImageUpload from 'src/components/form/ImageUpload';
import InputModal from 'src/components/form/InputModal';
import InputSwitch from 'src/components/form/InputSwitch';
import Url from 'src/components/url/Url';
import { WS_ERRORS } from 'src/core/webservices/WS_ERRORS';
import showGenericWsErrorNotification from 'src/core/webservices/showGenericWsErrorNotification';
import NotificationLevels from 'src/components-standalone/notifications/NotificationLevels';
import { getValueFromStringAttribute } from 'src/core/util/JsTools';

import './UserDataForm.scss';

const LOG_PREF = '[UserDataPageContent] ';

export const SAVING_NOTIF_ID = 'user-data-saving-notif';

class UserDataForm extends Component {
  getFieldConfiguration(fieldName) {
    let fieldConfig;
    for (let i = 0; i < this.props.sections.length && !fieldConfig; i++) {
      let fieldsName = Object.keys(this.props.sections[i].fields);
      if (fieldsName.indexOf(fieldName) !== -1) {
        fieldConfig = this.props.sections[i].fields[fieldName];
      }
    }
    return fieldConfig;
  }

  parseValue = (fieldName, value) => {
    let fieldConfig = this.getFieldConfiguration(fieldName);

    switch (fieldConfig.type) {
      case 'bool':
        return !!value;

      case 'number':
        return parseInt(value, 10);

      default:
        return value;
    }
  };

  remoteSave = (fieldName, value) => {
    let newValue = this.parseValue(fieldName, value);

    if (newValue === this.props.userData[fieldName]) {
      // Value is the same - skip remote save
      // This occurs for instance when the
      // profile picture is deleted on another device.
      return;
    }

    // proceed to update
    this.props.actions.remoteSaveUserData({
      [fieldName]: newValue,
    });
  };

  /**
   * Check error for a single field
   * @return {boolean}
   */
  getFieldClassName = (name, value) => {
    let fieldConfig = this.getFieldConfiguration(name);
    if (!fieldConfig) {
      console.error(LOG_PREF + `Handling undeclared field '${name}' (should not happen)`);
      return '';
    }

    // value is a string or undefined
    if ((!value || value.length === 0) && fieldConfig.isRequired === true) {
      return 'generic-form-error';
    }
    if (fieldConfig.pattern && fieldConfig.pattern.test(value) !== true) {
      return 'generic-form-error';
    }
    return '';
  };

  /*areEquals(val1, val2) {
        if (typeof val1 === 'object') {
            return JSON.stringify(val1) === JSON.stringify(val2);
        }
        return val1 === val2;
    }*/

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps &&
      prevProps.status &&
      JSON.stringify(prevProps.status) !== JSON.stringify(this.props.status)
    ) {
      let { saving, error } = this.props.status;

      // status has change

      if (!prevProps.status.saving && saving) {
        // 1-request ongoing
        this.props.actions.showNotification({
          uid: SAVING_NOTIF_ID,
          message: this.props.labels.common.saving,
          duration: 15,
        });
      } else if (prevProps.status.saving && !saving) {
        // 2-request end
        this.props.actions.removeNotification(SAVING_NOTIF_ID);
        // Use incoming this.props.userdata / purge local state
        this.resetLocalStates();

        if (!error) {
          // success
          this.props.actions.showNotification({
            message: this.props.labels.common.saved,
            duration: 2,
            level: NotificationLevels.SUCCESS,
          });
        } else {
          // failure
          switch (error) {
            case WS_ERRORS.AUTH:
            case WS_ERRORS.NETWORK:
              showGenericWsErrorNotification(error, NotificationLevels.ERROR);
              break;

            default:
              this.props.actions.showNotification({
                message: this.props.labels.userData.updateFailed,
                level: NotificationLevels.ERROR,
              });
          }
        }
      }
    }
  }

  setSwitchRef = (ref) => {
    if (!this._refs) {
      this._refs = {};
    }
    if (ref) {
      this._refs[ref.getFieldName()] = ref;
    }
  };

  resetLocalStates() {
    if (this._refs) {
      Object.keys(this._refs).forEach((fieldName) => {
        let ref = this._refs[fieldName];
        if (ref) {
          ref.resetState();
        }
      });
    }
  }

  handleLogoutClicked = () => {
    this.props.logout();
    this.props.actions.navigateToHome();
  };

  renderSection = (section, index) => (
    <section key={index} className={section.className}>
      {section.label && (
        <label>{getValueFromStringAttribute(this.props.labels, section.label)}</label>
      )}
      <div className={`ud-section-fields`}>
        {Object.keys(section.fields).map((fieldName) =>
          this.renderRow({ fieldName, displayLabel: section.displayLabel })
        ) /** displayLabel: bool from config-default */}
      </div>
    </section>
  );

  getFieldLabel = (fieldName) => this.props.labels.userData.fields[fieldName].label;

  getFieldPlaceholder = (fieldName) => this.props.labels.userData.fields[fieldName].placeholder;

  getDialogMaxHeight() {
    return document.documentElement.clientHeight - this.props.keyboardHeight;
  }
  /**
   * fieldName:string,
   * displayLabel:bool from config-default
   */
  renderRow = ({ fieldName, displayLabel }) => {
    let field = this.getFieldConfiguration(fieldName),
      value = this.props.userData[fieldName];

    if (field.condition && !this.props.userData[field.condition]) {
      return null;
    }

    switch (field.type) {
      case 'bool':
        return (
          <InputSwitch
            ref={this.setSwitchRef}
            key={fieldName}
            name={fieldName}
            label={this.getFieldLabel(fieldName)}
            value={!!value}
            editable={field.editable}
            submit={this.remoteSave}
          />
        );

      case 'image':
        return (
          <ImageUpload
            key={fieldName}
            sourceTypeModaltitle={this.props.labels.userData.picture.selectYours}
            image={value}
            onSelect={(image) => {
              this.remoteSave(fieldName, image);
            }}
            requestStatus={this.props.status}
            readOnly={!field.editable}
            labels={this.props.labels}
            actions={this.props.actions}
          />
        );
      default:
        return (
          <InputModal
            key={fieldName}
            name={fieldName}
            type={field.type}
            label={this.getFieldLabel(fieldName)}
            initialValue={value || ''}
            displayLabel={displayLabel}
            readOnly={!field.editable}
            required={field.required}
            disabled={false} // !field.editable
            placeHolder={this.getFieldPlaceholder(fieldName)}
            fieldClassName={this.getFieldClassName(fieldName, value)}
            renderClickableInput={this.renderInput}
            renderModalInput={this.renderInput}
            submit={this.remoteSave}
            requestStatus={this.props.status}
            labels={this.props.labels}
            maxHeight={this.getDialogMaxHeight()}
          />
        );
    }
  };

  renderInput({
    name,
    label,
    className,
    placeholder,
    type,
    value,
    onBlur,
    readOnly,
    disabled,
    required,
    onClick,
    onChange,
    displayLabel,
  }) {
    return (
      <div className={`form-input-group`}>
        {displayLabel && (
          <label htmlFor={name} className="text-color1">
            <span dangerouslySetInnerHTML={{ __html: label }} />
          </label>
        )}
        <input
          name={name}
          className={className}
          placeholder={placeholder}
          type={type}
          value={value}
          onBlur={onBlur}
          readOnly={readOnly}
          disabled={disabled}
          required={required}
          onClick={onClick}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
      </div>
    );
  }

  renderButtons = () => [
    <div key={0} className="generic-btn cta-btn -round" onClick={this.handleLogoutClicked}>
      {this.props.labels.login.logout}
    </div>,
  ];

  render() {
    let extRes = this.props.userData.externalResources;

    return (
      <div id="ud-container" className="content-font">
        <form id="ud-form">{this.props.sections.map(this.renderSection)}</form>

        {extRes && extRes.url && (
          <div className="ud-ext-ressources">
            <Url
              href={extRes.url}
              label={this.props.labels.userData.externalResources}
              target={this.props.externalResourceLinkTarget}
              openInInAppBrowser={this.props.externalResourceLinkOpenInInAppBrowser}
            />
          </div>
        )}

        {extRes && extRes.badge && (
          <div className="ud-ext-ressources">
            <Url
              href={extRes.badge}
              label={this.props.labels.userData.badge}
              target={this.props.externalResourceLinkTarget}
              openInInAppBrowser={this.props.externalResourceLinkOpenInInAppBrowser}
            />
          </div>
        )}

        {this.props.tosLink && (
          <div className="ud-link-container">
            <Url
              href={this.props.tosLink}
              label={this.props.labels.login.tos}
              target={this.props.tosLinkTarget}
              openInInAppBrowser={this.props.tosLinkOpenInInAppBrowser}
            />
          </div>
        )}

        <div className="generic-btn-container">{this.renderButtons()}</div>
      </div>
    );
  }
}

UserDataForm.propTypes = {
  userData: PropTypes.object,
  status: PropTypes.object,
  sections: PropTypes.array.isRequired,
  tosLink: PropTypes.string,
  logout: PropTypes.func.isRequired,
  keyboardHeight: PropTypes.number,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default UserDataForm;
