import React from 'react';
import PropTypes from 'prop-types';

import showConfirmModal from 'src/core/util/showConfirmModal';
import Header from '../header/Header';
import Content from '../content/Content';
import Footer from '../footer/Footer';
import FooterBackButton from '../footer/FooterBackButton';
import StatusBlock from '../status-block/StatusBlock';
import TermsOfUse from '../terms-of-use/TermsOfUse';

import './ExtendedMenuScreen.scss';
import '../disclaimer-common.scss';

function ExtendedMenuScreen({
  context,
  totalContactsCount,
  nonSyncContactsCount,
  actions,
  labels,
}) {
  function onHeaderCloseClick() {
    actions.navigateToHome();
  }

  function onResetLicenceClick() {
    const message =
      nonSyncContactsCount > 0
        ? labels.klipsoLeads.resetLicenceWithNonSyncContacts
        : labels.klipsoLeads.resetLicenceConfirm;

    showConfirmModal({
      title: labels.klipsoLeads.resetLicenceConfirmTitle,
      text: message,
      yesCb: () => {
        actions.klipsoLeadsResetLicence();
        actions.klipsoLeadsNavigate();
      },
      noCb: global.noop,
    });
  }

  /* function onExportClick() {
        console.log('TODO: onExportClick');
    } */

  function onBackClick() {
    actions.klipsoLeadsExitExtendedMenuScreen();
  }

  return (
    <div id="kl-ems">
      <Header
        subtitle={context.exhibitor.name}
        labels={labels}
        actions={actions}
        onCloseClick={onHeaderCloseClick}
      />

      <Content>
        <StatusBlock eventName={context.event.name} />

        <div className="kl-ems-licence-username-row">
          <div>
            <Row
              icon="fas fa-user"
              label={labels.klipsoLeads.extendedMenuScreenFields.userName}
              value={context.userName}
            />
            <Row
              icon="fas fa-key"
              label={labels.klipsoLeads.extendedMenuScreenFields.licence}
              value={context.licence}
            />
          </div>
          <div className="kl-ems-reset-licence" onClick={onResetLicenceClick}>
            {labels.klipsoLeads.resetLicence}
          </div>
        </div>

        {/* Currently disabled
                <div
                    className="kl-ems-export"
                    onClick={onExportClick}
                >
                    <div className="kl-ems-export-label">{labels.klipsoLeads.exportContacts}</div>
                    <div className="kl-ems-export-icon far fa-external-link" />
                </div>
                */}

        <div className="kl-ems-tos">
          <TermsOfUse labels={labels} />
        </div>

        <div
          className="kl-disclaimer"
          dangerouslySetInnerHTML={{ __html: labels.klipsoLeads.disclaimer }}
        />
      </Content>
      <div className="kl-versionNumber">v{process.env.APP_VERSION}</div>
      
      <Footer justifyContent="start">
        <FooterBackButton callback={onBackClick} labels={labels} />
      </Footer>
      
    </div>
  );
}

ExtendedMenuScreen.propTypes = {
  context: PropTypes.object, // licence, userName, exhibitor, event, checkpointId...
  totalContactsCount: PropTypes.number.isRequired,
  nonSyncContactsCount: PropTypes.number.isRequired,
  actions: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
};

export default ExtendedMenuScreen;

function Row({ icon, label, value }) {
  return (
    <div className="kl-ems-row">
      <div className={`kl-ems-r-icon ${icon}`} />
      <div className="kl-ems-r-label">{label}</div>
      <div className="kl-ems-r-value">{value}</div>
    </div>
  );
}
